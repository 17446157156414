import axios, { type AxiosError } from 'axios';

import prepareFormData from 'Modules/prepareFormData';
import type { AccountType } from 'src/models/accountLogin';
import { OtpOperationType } from 'src/models/otp';
import { fetcher } from 'src/utils/fetcher';

import {
    LoginByCodeErrorKey,
    LoginByPasswordErrorKey,
    type PostLoginByCodeError,
    type PostLoginByCodePayload,
    type PostLoginByCodeResult,
    type PostLoginByPasswordPayload,
    type PostLoginByPasswordResult,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useLogin/types';

const LOGIN_BY_CODE_URL = '/account/login/by_code';
const LOGIN_BY_PASSWORD_URL = '/account/login';

export const isLoginByCodeErrorKey = (error?: unknown): error is LoginByCodeErrorKey =>
    error ? Object.values(LoginByCodeErrorKey).includes(error as LoginByCodeErrorKey) : false;

export const isLoginByPasswordErrorKey = (error?: unknown): error is LoginByPasswordErrorKey =>
    error ? Object.values(LoginByPasswordErrorKey).includes(error as LoginByPasswordErrorKey) : false;

export const OperationTypeMap: Record<AccountType, OtpOperationType> = {
    APPLICANT: OtpOperationType.ApplicantOtpAuth,
    EMPLOYER: OtpOperationType.EmployerOtpAuth,
};

export const postLoginByCode = async (payload: PostLoginByCodePayload): Promise<PostLoginByCodeResult> => {
    try {
        const formData = prepareFormData(payload);
        const response = await fetcher.post(LOGIN_BY_CODE_URL, formData);

        return { success: true, data: response.data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const errorData = (error as AxiosError<PostLoginByCodeError>).response?.data;
            const key = errorData?.error?.key;

            if (isLoginByCodeErrorKey(key)) {
                return { success: false, error: { key, data: { ...errorData } } };
            }

            throw error;
        }

        return { success: false, error: { key: LoginByCodeErrorKey.Default } };
    }
};

export const postLoginByPassword = async ({
    url,
    ...payload
}: PostLoginByPasswordPayload): Promise<PostLoginByPasswordResult> => {
    try {
        const loginByPasswordUrl = (url ?? LOGIN_BY_PASSWORD_URL) as typeof LOGIN_BY_PASSWORD_URL;
        const formData = prepareFormData(payload);
        const response = await fetcher.post(loginByPasswordUrl, formData);

        return { success: true, data: response.data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const key = (error as AxiosError<{ error: { key: string } }>).response?.data?.error?.key;

            if (isLoginByPasswordErrorKey(key)) {
                return { success: false, error: key };
            }

            throw error;
        }

        return { success: false, error: LoginByPasswordErrorKey.Unknown };
    }
};
